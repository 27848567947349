<template>
  <div
    class="lg:w-64 sm:w-1/2 w-full date-holder flex items-center sm:justify-between md:space-x-2"
    :class="classList"
  >
    <p class="leading-relaxed font-semibold text-md sm:text-sm w-1/3">
      {{ title }}:
    </p>

    <div
      class="relative bg-gray-50 border border-gray-300 h-10 w-2/3 sm:w-full md:w-52 rounded-md"
      :class="datePickerClass"
    >
      <div class="absolute left-4 top-2">
        <svg
          data-v-faa3871e=""
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          fill="currentColor"
          class="h-6 w-auto"
        >
          <path
            data-v-faa3871e=""
            d="M57 8h-5V6a4 4 0 00-8 0v2h-8V6a4 4 0 00-8 0v2h-8V6a4 4 0 00-8 0v2H7a5 5 0 00-5 5v40a5 5 0 005 5h28a1 1 0 000-2H7a3.009 3.009 0 01-3-3V22h56v17a1 1 0 002 0V13a5 5 0 00-5-5zM46 6a2 2 0 014 0v6a2 2 0 01-4 0zM30 6a2 2 0 014 0v6a2 2 0 01-4 0zM14 6a2 2 0 014 0v6a2 2 0 01-4 0zm46 14H4v-7a3.009 3.009 0 013-3h5v2a4 4 0 008 0v-2h8v2a4 4 0 008 0v-2h8v2a4 4 0 008 0v-2h5a3.009 3.009 0 013 3z"
          ></path>
          <path
            data-v-faa3871e=""
            d="M30 29a2 2 0 00-2-2h-4a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3v-3h4v3zM18 29a2 2 0 00-2-2h-4a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3v-3h4v3zM52 34a2 2 0 002-2v-3a2 2 0 00-2-2h-4a2 2 0 00-2 2v3a2 2 0 002 2zm-4-5h4v3h-4zM30 38a2 2 0 00-2-2h-4a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3v-3h4v3zM18 38a2 2 0 00-2-2h-4a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2zm-6 3v-3h4v3zM28 45h-4a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2v-3a2 2 0 00-2-2zm-4 5v-3h4v3zM36 34h4a2 2 0 002-2v-3a2 2 0 00-2-2h-4a2 2 0 00-2 2v3a2 2 0 002 2zm0-5h4v3h-4zM34 41a2 2 0 002 2 1 1 0 000-2v-3h4a1 1 0 000-2h-4a2 2 0 00-2 2zM16 45h-4a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2v-3a2 2 0 00-2-2zm-4 5v-3h4v3z"
          ></path>
          <path
            data-v-faa3871e=""
            d="M49 36a13 13 0 1013 13 13.015 13.015 0 00-13-13zm0 24a11 11 0 1111-11 11.013 11.013 0 01-11 11z"
          ></path>
          <path
            data-v-faa3871e=""
            d="M54.778 44.808L47 52.586l-3.535-3.536a1 1 0 00-1.414 1.414l4.242 4.243a1 1 0 001.414 0l8.485-8.485a1 1 0 00-1.414-1.414z"
          ></path>
        </svg>
      </div>
      <date-picker
        v-model="_date"
        startingView="month"
        class="absolute top-0 pl-16 bg-transparent py-2 focus:outline-none cursor-pointer font-semibold"
      ></date-picker>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue3-datepicker";
export default {
  components: { "date-picker": DatePicker },
  props: {
    title: { type: String },
    newDate: { required: true },
    classList: { type: Array },
    datePickerClass: { type: Array },
  },
  computed: {
    _date: {
      get() {
        return this.newDate;
      },
      set(value) {
        this.$emit("updateDate", value);
      },
    },
  },
  data: () => ({
    selectedDate: "",
    pickerTheme: {
      background: "#FFFFFF",
      text: "text-gray-700",
      border: "border-gray-200",
      currentColor: "text-gray-200",
      navigation: {
        background: "bg-gray-100",
        hover: "hover:bg-gray-200",
        focus: "bg-gray-200",
      },
      picker: {
        rounded: "rounded-full",
        selected: {
          background: "bg-blue-500",
          border: "border-blue-500",
          hover: "hover:border-blue-500",
        },
        holiday: "text-red-400",
        weekend: "text-green-400",
        event: "bg-indigo-500",
      },
      event: {
        border: "border-gray-200",
      },
    },
  }),
};
</script>

<style>
#v-tailwind-picker > div {
  /* z-index: 2000 !important; */
  /* width: auto !important; */
}

.v3dp__datepicker input {
  width: 100%;
}

.v3dp__datepicker input[data-v-a46a390c] {
  width: 150px !important;
  height: 38px !important;
}

.v3dp__popout {
  width: 230px !important;
}
</style>
